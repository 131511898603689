<template>
  <button
    type="button"
    class="w-full p-5 space-x-2 flex items-center justify-between border-solid rounded-2xl focus:outline-none focus:shadow-focus"
    :class="isChecked ? 'border-2 border-16-Agency-500' : 'border border-17-Grey-32%'"
    @click="emit('update:modelValue', id)"
    @keydown.space.prevent="emit('update:modelValue', id)"
  >
    <span
      class="text-left space-y-2"
    >
      <span
        class="block font-roc-grotesk-medium text-2xl text-Light-01-Text-Primary leading-7"
      >
        {{ name }}
      </span>
    </span>
    <span
      v-if="image_url"
      class="h-[56px] flex items-center"
    >
      <client-only>
        <NuxtImg
          v-show="isMainImageLoaded && !hasMainImageError"
          :src="image_url"
          class="w-[56px]"
          format="webp"
          @load="isMainImageLoaded = true"
          @error="hasMainImageError = true"
        />
      </client-only>
      <span
        v-if="!isMainImageLoaded && !hasMainImageError"
        class="inline-block w-[56px] h-auto bg-gradient-to-r from-07-Grey-300 to-07-Grey-100 rounded-3xl animate-pulse"
      />
    </span>
    <IconLoader
      v-if="(!image_url && !isNoPartner) || (hasMainImageError && !isNoPartner)"
      name="ic_partner_remove"
      class="shrink-0 h-[56px] w-[56px] rounded-xl"
    />
    <IconLoader
      v-if="(!image_url && isNoPartner) || (hasMainImageError && isNoPartner)"
      name="ic_partner"
      class="shrink-0 h-[56px] w-[56px] rounded-xl"
    />
  </button>
</template>

<script setup lang="ts">
import type { Partner } from '~/types/Partner'

interface Props extends Partner {
  modelValue: string | null
}

const props = defineProps<Props>()

interface Emits {
  (e: 'update:modelValue', value: string): void
}

const emit = defineEmits<Emits>()

const isNoPartner = computed((): boolean => props.name.toLowerCase() === 'no partner')
const isChecked = computed((): boolean => props.modelValue === props.id)

const isMainImageLoaded = ref<boolean>(false)
const hasMainImageError = ref<boolean>(false)
</script>
