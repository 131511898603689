<template>
  <button
    type="button"
    class="w-full p-5 font-roc-grotesk-medium text-xl text-left text-Light-01-Text-Primary leading-7 border-solid rounded-2xl focus:outline-none focus:shadow-focus"
    :class="isChecked ? 'border-2 border-16-Agency-500' : 'border border-17-Grey-32%'"
    @click="emit('update:modelValue', id)"
    @keydown.space.prevent="emit('update:modelValue', id)"
  >
    {{ name }}
  </button>
</template>

<script setup lang="ts">
import type { Country } from '~/types/Country'

interface Props extends Country {
  modelValue: string | null
}

const props = defineProps<Props>()

interface Emits {
  (e: 'update:modelValue', value: string): void
}

const emit = defineEmits<Emits>()

const isChecked = computed((): boolean => props.modelValue === props.id)
</script>
