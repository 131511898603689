<template>
  <div>
    <SectionHeading>
      <template
        #headline
      >
        {{ $t('country_selection.headline') }}
      </template>
      <template
        #subline
      >
        {{ $t('country_selection.subline') }}
      </template>
    </SectionHeading>
    <div
      class="space-y-4 relative"
    >
      <CountryComponent
        v-for="country of countries"
        :key="country.id"
        :model-value="selectedCountryId"
        v-bind="country"
        @update:model-value="emit('update:selectedCountryId', $event)"
      />
      <ValidationComponent
        :error-message="$t('validation.select_country')"
        :value="selectedCountryId"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { Country } from '~/types/Country'

interface Props {
  countries: Array<Country>
  selectedCountryId: string | null
}

const props = defineProps<Props>()

interface Emits {
  (e: 'update:selectedCountryId', value: string): void
}

const emit = defineEmits<Emits>()
</script>
