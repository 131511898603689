<template>
  <div>
    <SectionHeading>
      <template
        #headline
      >
        {{ $t('partner_selection.headline') }}
      </template>
      <template
        #subline
      >
        {{ $t('partner_selection.subline') }}
      </template>
    </SectionHeading>
    <div
      class="space-y-4 relative"
    >
      <PartnerComponent
        v-for="partner in partners"
        :key="partner.id"
        :model-value="selectedPartnerId"
        v-bind="partner"
        tabindex="0"
        @update:model-value="emit('update:selectedPartnerId', $event)"
      />
      <ValidationComponent
        :value="selectedPartnerId"
        :error-message="$t('validation.select_partner')"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { Partner } from '~/types/Partner'

interface Props {
  partners: Array<Partner>
  selectedPartnerId: string | null
}

const props = defineProps<Props>()

interface Emits {
  (e: 'update:selectedPartnerId', value: string): void
}

const emit = defineEmits<Emits>()
</script>
