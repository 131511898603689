<template>
  <div
    class="w-full lg:grid lg:gap-8 lg:grid-cols-2 grid-rows-1 mx-auto lg:h-full"
  >
    <div
      class="hidden lg:block w-full lg:h-full"
    >
      <client-only>
        <NuxtImg
          v-show="isMainImageLoaded"
          src="/images/cycle.png"
          class="w-full h-full object-cover"
          width="480"
          height="554"
          format="webp"
          sizes="sm:0px md:0px lg:468px xl:694px 2xl:838px"
          @load="isMainImageLoaded = true"
        />
      </client-only>
      <div
        v-show="!isMainImageLoaded"
        class="h-full w-full bg-gradient-to-r from-07-Grey-300 to-07-Grey-100 animate-pulse"
      />
    </div>
    <div
      class="w-full lg:h-full lg:overflow-y-auto"
    >
      <form
        id="storeSelection"
        v-auto-animate
        class="py-8 w-full max-w-[490px] mx-auto lg:px-1"
        @submit.prevent="onSubmitClick"
      >
        <client-only>
          <CountrySelection
            v-model:selected-country-id="selectedCountryId"
            v-auto-animate
            :countries="countries"
            @update:selected-country-id="resetSelection"
          />
          <PartnerSelection
            v-if="selectedCountryId"
            :key="selectedCountryId"
            v-model:selected-partner-id="selectedPartnerId"
            v-auto-animate
            :partners="partners"
            @update:selected-partner-id="selectedStore = null"
          />
          <template
            v-if="selectedPartnerId"
          >
            <MainStoreSelection
              v-model:selected-store="selectedStore"
              v-auto-animate
              :stores="sortedStores"
            />
          </template>
        </client-only>
        <div
          v-auto-animate
          class="py-8"
        >
          <ErrorSection
            v-if="errors"
            v-auto-animate
            :errors="errors"
          />
          <ButtonComponent
            v-auto-animate
            :disabled="isButtonDisabled"
            class="w-full"
            form="storeSelection"
            type="submit"
            :label="$t('button.main_form')"
          />
        </div>
      </form>
    </div>
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { useSelectionStore } from '~/store/selection-store'
import type { ErrorData } from '~/types/ErrorData'
import { formatErrors } from '~/utils/erorr-utils'
import type { Partner } from '~/types/Partner'
import type { Country } from '~/types/Country'
import { countries } from '~/constants'
import CountrySelection from '~/sections/CountrySelection.client.vue'
import PartnerSelection from '~/sections/PartnerSelection.client.vue'
import MainStoreSelection from '~/sections/MainStoreSelection.client.vue'
import ErrorSection from '~/sections/ErrorSection.vue'

definePageMeta({
  title: 'Checkout',
  middleware: 'index-middleware'
})

const partners = computed((): Array<Partner> => countries.find((country: Country) => country.id === selectedCountryId.value)?.partners?.filter((partner: Partner) => partner.visible !== false) ?? [])
const selectedDomain = computed((): string => partners.value.find((partner: Partner) => partner.id === selectedPartnerId.value)?.domain ?? 'none')

const router = useRouter()
const selectionStore = useSelectionStore()
const {
  selectedStore,
  selectedCountryId,
  selectedPartnerId,
  sortedStores
} = storeToRefs(selectionStore)
const errors = ref<ErrorData | undefined>()
const isButtonDisabled = computed((): boolean => !selectedStore.value?.id)
const isMainImageLoaded = ref<boolean>(false)

watch(selectedDomain, async (): Promise<void> => {
  if (selectedDomain.value === 'none') {
    return
  }
  const response = await selectionStore.getStores({ subdomain: selectedDomain.value })
  if (response?.error) {
    errors.value = formatErrors(response.error)
  }
},
{ immediate: true })

const resetSelection = (): void => {
  selectedPartnerId.value = null
}

const onSubmitClick = (): void => {
  if (!selectedStore.value) {
    return
  }

  void router.push({ path: '/registration', query: { storeId: selectedStore.value.id } })
}
</script>
